import React from 'react';
import { useCountdown } from '../hooks/useCountdown';
import LinearProgress from '@mui/material/LinearProgress';


const CountdownBar = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  return <LinearProgress variant="determinate" value={seconds*100/30}></LinearProgress>;
};

export default CountdownBar;