import { AppBar, Toolbar} from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import RegisterForm from './RegisterForm';
import Typography from '@mui/material/Typography';
import { StyledEngineProvider } from '@mui/material/styles';

 //https://www.copycat.dev/blog/material-ui-form/
function App() {
  return (
    <React.Fragment>
      <AppBar position='relative'>
        <Toolbar>
          <Typography align="center" variant="h5" component="div" sx={{ flexGrow: 1 }}>
            BioFace - Análise facial
          </Typography>
        </Toolbar>
      </AppBar>

      <StyledEngineProvider injectFirst>
        <Container>
          <RegisterForm/>
        </Container>
      </StyledEngineProvider>

    </React.Fragment>
  );
}
 
export default App;