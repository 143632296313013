import React from 'react';

const DateTimeDisplay = ({ value }) => {
  return (
    <div style={{float: 'left'}}>
      {(""+value).padStart(2, "0")} 
    </div>
  );
};

export default DateTimeDisplay;