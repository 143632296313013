import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {descriptionResults} from '../constants/response.js'
const ResponseData = ({ response }) => {

  return (

    <div>
        { response !== undefined && Object.keys(response).length > 0 &&
            <TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Índice</TableCell>
                        <TableCell align="right">Valor</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(response).map((t) => {
                            return (
                                <TableRow
                                key={t}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {descriptionResults[t]}
                                    </TableCell>
                                    <TableCell align="right">{response[t]} </TableCell>
                                </TableRow>
                            );
                        })} 
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </div>

  );
};

export default ResponseData;