
       //https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5739389/
       export const  descriptionResults = {
        "bmi"   : "Índice de massa corporal (IMC)", 
        "hr"    : "Frequência cardíaca ",
        "hrv"   : "Variabilidade da do batimento cardíaco (HRV)", 
        "rpm"   : "Respiração por minuto", 
        "spo2"  : "Oximetria",
        "bp_sys": "Pressão arterial sistólica",
        "bp_dia": "Pressão arterial diastólica",
        "stress_index"      : "Índice de stress",
        "heart_risk"       : "Risco cardíaco",
        "cardiac_workload" :  "Carga de trabalho cardíaca", 
        "cardiac_risk"      :"Risco cardíaco", 
        "heart_attack_risk": "Risco de ataque cardíaco", 
        "stroke_risk"       : "Risco de acidente vascular cerebra", 
        "wellness_score"    : "Pontuação de bem-estar"
    };