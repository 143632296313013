export const THIRTY_SECONDS = 30000;
export const TWENTY_SECONDS = 20000;
export const IP_SERVER = 'api.bioface.app';
export const WS_PORT = '8001';
export const WS_URL = 'wss://'+IP_SERVER+":"+WS_PORT+'/ws';
export const URL_RESULT = 'https://'+IP_SERVER+":"+WS_PORT+'/token_results/';

export const GET_RESULT = 'GET_RESULT';
export const CONNECTING = 'CONNECTING';
export const ERROR = 'ERROR';
export const RECORDING = 'RECORDING';
export const NOT_FOUND = 'NOT_FOUND';

 export const MESSAGE_STATUS = {
     CONNECTING : 'Conectando com servidor',
     ERROR : 'Erro de conexão com servidor',
     GET_RESULT : 'Aguarde, imagem em análise!',
     NOT_FOUND : 'Ocorreu um erro inesperado, tente novamente!'

 };
