
import React, {useState, useRef, useEffect} from 'react';
import { TextField, Button, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Webcam from 'react-webcam';
import { v4 as uuidv4 } from 'uuid';
import CountdownTimer from './components/CountdownTimer';
import CountdownBar from './components/CountdownBar';

import ResponseData from './components/ResponseData';


import {THIRTY_SECONDS,
        WS_URL ,    
        URL_RESULT,
        GET_RESULT ,
        CONNECTING ,
        ERROR,
        RECORDING,
        NOT_FOUND,
        MESSAGE_STATUS, 
        TWENTY_SECONDS} from './constants/constants.js'

import useWebSocket, { ReadyState } from 'react-use-websocket';


const RegisterForm = () => {

    //Variaveis de tela
    const [gender, setGender] = useState('')
    const [age, setAge] = useState('')
    const [height, setHeight] = useState('')
    const [weight, setWeight] = useState('')
    const [smoker, setSmoker] = useState(false)
    const [diabetes, setDiabetes] = useState(false)
    const [hypertension, setHypertension] = useState(false)
    const [response, setResponse] = useState({});

    //rever - useState chama o render - https://www.frontendundefined.com/posts/react-issues/react-usestate-hook-not-updating-immediately/
    const [isShowVideo, setIsShowVideo] = useState(false); //Controla exibição do video
    const [connectionOpened, setConnectionOpened] = useState(false); //controla inicio do processo de gravação
    const [messageStatus, setMessageStatus] = useState('');
    const [dateTimeToCountDown, setDateTimeToCountDown] = useState(0);
    const [resultCountDown, setResultCountDown] = useState(0);
  
    //Variaveis de controle
    const uuid = useRef('');
    const actionStatus  = useRef('');
    const idCapture= useRef(0);
   
    //Video
    const videoElement = useRef(null);

     const delayToWSProcess = ms => new Promise(res => setTimeout(res, ms)); 

    /***Eventos do conector */
    const { sendMessage, sendJsonMessage, readyState } = useWebSocket(WS_URL, {
        onError: (error)  => wsError(error),
        share: true,
        filter: () => false,
        shouldReconnect: () => true,
    });


    const video_height  =useRef(0); 
    const video_width   =useRef(0); 
    const video_fps     = useRef(30); 

    const videoConstraints = useRef({
        height: video_height.current,
        width: video_width.current,
        facingMode: "user"
     });


     useEffect(() => {
        let ignore = false;
        
        if (!ignore)  {
            onOpenApp();
        }
        return () => { ignore = true; }
    },[]);


     async function onOpenApp(){

        let constraints = { 
            audio: false, 
           video: { 
                width: { min: 100 }, //tenta obter o menor tamanho possível
                height: { min: 100 } //tenta obter o menor tamanho possível
            }
        };

        let stream = await navigator.mediaDevices.getUserMedia(constraints);

        let stream_settings = stream.getVideoTracks()[0].getSettings();
    
        // actual width & height of the camera video
        let stream_width = stream_settings.width;
        let stream_height = stream_settings.height;

        video_width.current = stream_width;
        video_height.current = stream_height;
        video_fps.current = stream_settings.frameRate;

        videoConstraints.current.width = stream_width;
        videoConstraints.current.height = stream_height;
        stream.getVideoTracks()[0].stop();

     }

    /**
     * 
     * Envia a primeira mensagem para o servidor e inicia o processo de captura de imagem
     * Evento deve ser chamado somente após a camera aberta
     */
    function startCaptureProcess(){

        setConnectionOpened(true);
        setActionStatus(RECORDING);

        //Gera um token
        uuid.current =  uuidv4();
      
        let value = {
            action: "start",
            age: parseInt(age),
            diabetes: diabetes,
            gender: gender,
            height: parseInt(height),
            hypertension: hypertension,
            pid: uuid.current,
            smoker: smoker,
            token: uuid.current,
            video_fps: video_fps.current,
            video_height: video_height.current,      
            video_width: video_width.current,
            weight: parseInt(weight)
            };
       
        sendJsonMessage(value); 
     }

    /**
     * Evento disparado quando a conexão é aberta
     */
    useEffect(() => {
        let id;
        if (isShowVideo) {
           
            //hora que o processo inicia - para contabilizar 30seg
            let endTime  = ( new Date()).getTime();
           
            setDateTimeToCountDown(endTime + THIRTY_SECONDS);

            id = setInterval(() => {
                catptureAndSend();
                   

                //teste o tempo de execução
                let date = new Date();
                if(date.getTime() >= (endTime + 30000)){
                    clearInterval(id);
                    endRecording();
                    endTime = 0;
                }
             
            }, 33);

            setIdCapture(id);
        }
        return () => clearInterval(id);
    }, [ connectionOpened,isShowVideo]);


    /**
     * Dispara quando a camera é exibida
     */
    const handleUserMedia = () => {
        setTimeout(() => { 
          startCaptureProcess();
        }, 1000);
      };

    /**
     * Dispara o processo de abertura da camera e evio ao WS
     * @returns 
     */
    const startRecording = () => {

        //Deve finalizar o primeiro processamento
        if(actionStatus.current === GET_RESULT){
            return;
        }

        //Se dados não preenchidos
        if(!formFields.current.reportValidity()){
            return;
        }

        setActionStatus(CONNECTING);

        //limpa qualquer vestigio de erro
        setResponse([]);

       //Abre a camera
       startCam();
    }

    /**
     * Botão finalizar
     */
    const endRecording = () => {
       
        if(idCapture.current && idCapture.current > 0){
            clearInterval(idCapture);
            setDateTimeToCountDown(0);
            //Encerra o processo de gravação
            closeWebCam();
            //Inicia a análise da imagem
            getAnalysisResult();
        }
    }

    /**
     * 
     */
    const closeWebCam = () => {
        setIsShowVideo(false);
        let stream = videoElement?.current?.stream;
        if(stream){
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop()); 
        } 
    }

    async function getAnalysisResult() {

        //Finaliza o processo  no WS
        sendMessage(JSON.stringify({ action: 'stop' }));

        setActionStatus(GET_RESULT);   
        
        //timer para busca de dados
        setResultCountDown(( new Date()).getTime() + TWENTY_SECONDS);
        //é necessário esse loop pois o server não tem um tempo padrão de processamento e resposta
        //quando esta processando ele retorna que o id não existe (mesma mensagem para erro ou id de fato inexistente)
        let countTry = 0;
        do{
            setActionStatus(GET_RESULT);       
            fetch(URL_RESULT + uuid.current) //todo temporario
            .then(response => response.json())
            .then(json => {
               formatResult(json);
             })
            .catch(error => console.error(error));
            
            await delayToWSProcess(2000);

        } while (++countTry < 10 && actionStatus.current === GET_RESULT);

        displayNotFound();
        //encerra o timer
        setResultCountDown(-1);
        //força o socket fechar
        endCaptureProcess();
    }

    /**
     * 
     * @param {*} json 
     */
    function formatResult(json) {

        //Neste caso retornou uma mensagem de erro
        if(json?.constructor?.name === 'Object'){
            //aqui retornou um json que não achou o id (pode ser erro, processando ou não encontrado)
            //todo? APRESENTAR ESTE TOKEN EM TELA??
        } else { //retornou o Json em formato String

            setActionStatus(undefined);
        
            let responseTemp = JSON.parse(json);
            if(responseTemp['bmi']){
                delete responseTemp['elapsed_time'];
            } else{
                responseTemp = {};
            }
            //
            setResponse(responseTemp);
        }
    }

    /**
     * 
     */
    function displayNotFound(){

        if(actionStatus.current === GET_RESULT) {
            setActionStatus(NOT_FOUND);
        } 
    }

    /**
     * 
     */
    const startCam = () => {
        setIsShowVideo(true);
    }

 
    /**
     * 
     */
    function catptureAndSend(){
        const imageSrc = videoElement.current.getScreenshot({width: video_width.current, height: video_height.current});
        if(imageSrc && readyState === ReadyState.OPEN){
            let convert = base64ToBlob(imageSrc);
            sendMessage(convert);
           // saveBlob(convert)
        } 
    }

    /*function saveBlob(blob, fileName) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
    
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };*/

    /**
     * 
     * @param {*} str 
     * @returns 
     */
    function base64ToBlob(str) {

      let  base64String = str.split(',')[1];
      let  contentType = 'image/jpeg';

        const byteCharacters = atob(base64String);
        const byteArrays = [];
    
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }
    
        const byteArray = new Uint8Array(byteArrays);
        return new Blob([byteArray], { type: contentType });
    }

 

     /**
      * Conexão fechada
      */
     async function endCaptureProcess(){
        setIsShowVideo(false);
        setConnectionOpened(false);   
    }

    /**
     * 
     * @param {*} error 
     */
    function wsError(error){
        setActionStatus(ERROR);
        setIsShowVideo(false);   
        setConnectionOpened(false);
        console.log("WebSocket connection onError.", error);
    }

    /**
     * 
     * @param {*} value 
     * @returns 
     */
    const toIntValue = (value) =>{
        value = value.replace(/\D/g, "");
        return value;
    }

    /**
     * 
     * @param {*} value 
     * @returns 
     */
    const toPositiveValue = (value) =>{
        if(value < 0)
            return value * -1;
        return value;
    }

    /**
     * 
     * @param {*} newStatus 
     */
    function setActionStatus(newStatus){
        actionStatus.current = newStatus;
        setMessageStatus( MESSAGE_STATUS[actionStatus.current]);
   }

    /**
     * 
     * @param {*} id 
     */
    function setIdCapture(id){
        idCapture.current = id;
    }

    const formFields = React.useRef();
    return (
        <React.Fragment>
           
            <form  ref={formFields}>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                </Stack>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <FormControl fullWidth required   sx={{mb: 4}}>
                        <InputLabel id="gender-select-label">Genero</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            id="gender"
                            label="Genero"
                            value={gender}
                            onChange={e => setGender(e.target.value)}
                            required
                        >    
                        <MenuItem value={"Male"}>Masculino</MenuItem>
                        <MenuItem value={"Female"}>Feminino</MenuItem>
                        <MenuItem value={"Other"}>Outro</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        type="number"
                        variant='outlined'
                        color='secondary'
                        label="Idade"
                        min="1" step="1"
                        onChange={e => setAge(toIntValue(e.target.value))}
                        value={age}
                        required
                        fullWidth
                        sx={{mb: 4}}
                    />
                </Stack>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    
                    <TextField
                        type="number"
                        variant='outlined'
                        color='secondary'
                        label="Peso(kg)"
                        min="1" step="0.01"
                        onChange={e => setWeight(toPositiveValue(e.target.value))}
                        value={weight}
                        required
                        fullWidth
                        sx={{mb: 4}}
                    />
                    <TextField
                        type="number"
                        variant='outlined'
                        color='secondary'
                        label="Altura(cm)"
                        min="1" step="1"
                        onChange={e => setHeight(toIntValue(e.target.value))}
                        value={height}
                        required
                        fullWidth
                        sx={{mb: 4}}
                    />
                </Stack>

                <Stack 
                 direction={{ xs: 'column', sm: 'row' }}
                 spacing={{ xs: 1, sm: 2, md: 3 }}
                 sx={{marginBottom: 4}}>
                    <FormControlLabel 
                        control={<Checkbox />} 
                        label="Diabetes" 
                        checked={diabetes}
                        onChange={e => setDiabetes(!diabetes)}
                    />
                    <FormControlLabel 
                        control={<Checkbox />} 
                        label="Hipertensão" 
                        checked={hypertension}
                        onChange={e => setHypertension(!hypertension)}
                        />
                    <FormControlLabel  
                        control={<Checkbox />} 
                        label="Fumante" 
                        checked={smoker}
                        onChange={e => setSmoker(!smoker)}
                    />
                </Stack>

               <Stack spacing={2} direction="row" sx={{marginBottom: 4}}   justifyContent="center">
                    <Button variant="outlined" color="secondary"onClick={startRecording} disabled={isShowVideo}>Iniciar</Button>
                    <Button variant="outlined" color="secondary" onClick={endRecording}disabled={!isShowVideo} >Cancelar</Button>
                </Stack>
            </form>


            <div sx={{marginBottom: 4}}>
                {/** Mensagens informativas */}
                <span style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    }}>
                     {messageStatus !== '' && 
                        <span>{messageStatus}</span>}  
                    &nbsp;
                    {resultCountDown >0 &&
                        <CountdownTimer targetDate={resultCountDown} />
                    }

                </span>
            
                {/** Webcam */}
                {isShowVideo && <CountdownBar targetDate={dateTimeToCountDown}  />}
                <div className="camView" style={{
                    position: 'relative' ,
                    width: '100%',
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    alignItems: 'center',
                    }}>

                        {isShowVideo &&
                        <Webcam audio={false} 
                        ref={videoElement} 
                        videoConstraints={videoConstraints.current}
                        screenshotFormat="image/jpeg"
                        onUserMedia ={handleUserMedia}
                        width='100%'
                        height='100%'
                        />
                        }
                    {/**Relogio  - https://www.sitepoint.com/css-sizing-absolute-position/*/}
                    {isShowVideo && 
                    <div    style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            background: 'rgba(0, 0, 0, 0.3)',
                        }}>
                        <span style={{color:'white'}}> 
                            <CountdownTimer targetDate={dateTimeToCountDown} />
                        </span>
                    </div>}
                </div>
            </div>

            <ResponseData response={response} />
     
        </React.Fragment>
    )
}
 
export default RegisterForm;